body {
  background-color: black;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
}

.sameLine {
  display: inline;
}
